<template>
  <PageContainer>
    <PageTitle>
      <div class="w-full text-center flex flex-col justify-center items-center font-helvNeu font-semibold">
        <Slogan
          class="pb-1 lg:pb-2 pt-6"
          text1="LOGO:Segment1"
          text2="LOGO:Segment2"
          :text2-highlighted="true"
          text3="LOGO:Segment3"
          desktop-size="7xl"
          :mobile-divider=8
        />
      </div>
    </PageTitle>
    <div class="text-2xl mt-30 lg:mt-60 flex flex-col items-center gap-5 justify-center">
      <span class="text-2xl ">
        {{ trans('MESSAGE:404') }}
      </span>
      <Button
        @clicked="$router.back()"
      >
        <base-icon color="fill-white" name="back">
        </base-icon>
        <span class="ml-2">
          {{ trans('BUTTON:BackToPreviousPage') }}
        </span>
      </Button>
    </div>

  </PageContainer>
</template>

<script>

import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import Slogan from "@/views/content/components/Homepage/Slogan";
export default {
  name: "Error404",
  components: { BaseIcon, Button, Slogan, PageTitle, PageContainer},
}
</script>